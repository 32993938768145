// vendors
import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { css } from "@emotion/core"
import { Formik } from "formik"
import { Link } from "gatsby"

// components
import Button from "../Button/Button"
import { colors, fontSizes } from "../../styles/variables"
import wrapper from "../../utils/wrapper"
import mediaQuery from "../../utils/media-query"
import Input from "../Input"

// images
import LogoFacebook from "../../images/LogoFacebook"
import LogoSarros from "../../images/logo-sarros-corpo.svg"
import LogoInstagram from "../../images/LogoInstagram"
import LogoYoutube from "../../images/LogoYoutube"

const SiteFooter = () => {
  // const
  const submitForm = async (values, { setSubmitting, setStatus }) => {
    const result = await addToMailchimp(values.email, {
      b_6c549d491c18980f498cc19c3_a23b5f5e22: "",
    })

    setStatus(result)
    setSubmitting(false)
  }

  return (
    <footer
      css={css`
        ${wrapper.bolt("padding")}
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto, 4);
        background-color: ${colors.darkTeal};

        ${mediaQuery.greaterThen(1024)} {
          grid-template-columns: auto minmax(100px, 500px);
          grid-template-rows: repeat(auto, 2);

          padding-top: ${fontSizes[9] / fontSizes[0]}em;
          padding-bottom: ${fontSizes[9] / fontSizes[0]}em;
        }
        font-size: ${fontSizes[1]}px;
        line-height: ${fontSizes[2] / fontSizes[1]};

        p {
          color: ${colors.offWhite};
        }
        > * {
          padding: ${fontSizes[7] / fontSizes[1]}em 0;
        }

        .title {
          font-size: ${fontSizes[4] / fontSizes[1]}em;
          line-height: ${fontSizes[4] / fontSizes[3]};
          font-weight: 600;

          ${mediaQuery.greaterThen(1024)} {
            font-size: ${fontSizes[5] / fontSizes[1]}em;
          }
        }
      `}
    >
      <div
        css={css`
          grid-row: 1 / span 1;
          grid-column: 1 / span 1;
          border-bottom: 2px solid ${colors.offWhite};

          ${mediaQuery.greaterThen(1024)} {
            grid-row: 1 / span 1;
            grid-column: 2 / span 1;
          }
        `}
      >
        <Formik
          initialValues={{ email: "" }}
          validate={(values) => {
            let errors = {}
            if (!values.email) {
              errors.email = "Une adresse courriel est nécessaire"
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "L'adresse courriel est invalide"
            }
            return errors
          }}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            status,
          }) => (
            <form onSubmit={handleSubmit}>
              <p className="title">
                Abonnez-vous à l’infolettre pour ne rien manquer.
              </p>

              {!status && (
                <div>
                  <div
                    css={css`
                      margin-bottom: 2em;
                    `}
                  >
                    <Input
                      type="email"
                      placeholder="votre courriel"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.email}
                    />

                    {errors.email && (
                      <p
                        css={css`
                          color: ${colors.pastelRed} !important;
                          margin-left: ${40 / fontSizes[1]}em;
                        `}
                      >
                        {errors.email}
                      </p>
                    )}
                  </div>

                  <Button
                    type="submit"
                    secondary
                    disabled={isSubmitting}
                    hoverColor={colors.offWhite}
                  >
                    S'inscrire
                  </Button>
                </div>
              )}

              {status && <p dangerouslySetInnerHTML={{ __html: status.msg }} />}
            </form>
          )}
        </Formik>
      </div>

      <nav
        css={css`
          grid-row: 2 / span 1;
          grid-column: 1 / span 1;
          border-bottom: 2px solid ${colors.offWhite};

          a {
            color: ${colors.offWhite};
            text-decoration: none;
          }

          ${mediaQuery.greaterThen(1024)} {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;
            padding-right: 20px;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }
        `}
      >
        <ul
          css={css`
            max-width: 674px;
            display: flex;
            flex-wrap: wrap;
            grid-row-gap: ${fontSizes[7] / fontSizes[1]}em;
            justify-content: space-between;

            ${mediaQuery.greaterThen(1024)} {
              && {
                margin: 2em 0;
              }
            }

            > li {
              flex-shrink: 1;
              margin-right: 20px;
              max-width: 274px;
              margin-bottom: ${fontSizes[6] / fontSizes[0]}em;

              :last-of-type {
                margin-right: 0;
                margin-bottom: 0;
              }
            }

            ul li {
              margin-bottom: 1.5em;

              :last-of-type {
                margin-bottom: 0;
              }
            }
          `}
        >
          <li>
            <p className="title">Je cherche</p>

            <ul>
              <li>
                <Link to="/stage">Information sur les stages</Link>
              </li>

              <li>
                <Link to="/emploi">Emplois disponibles</Link>
              </li>

              <li>
                <Link to="/regions">Régions SARROS</Link>
              </li>

              <li>
                <Link to="/contact">Contacts en région SARROS</Link>
              </li>

              <li>
                <Link to="/evenements">Événements à venir</Link>
              </li>

              <li>
                <Link to="/quiz/decouvrez-votre-region-sarros">
                  Quiz - Découvrez votre région SARROS
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <p className="title">Aide</p>

            <ul>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>

              <li>
                <Link to="/liens-utiles">Liens utiles</Link>
              </li>

              <li>
                <Link to="/stage#bourses-et-soutien">Aide pour les stages</Link>
              </li>

              <li>
                <Link to="/emploi#soutien">Soutien aux nouveaux médecins</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <div
        css={css`
          grid-row: 3 / span 1;
          grid-column: 1 / span 1;

          ${mediaQuery.greaterThen(1024)} {
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            font-weight: bold;
            max-height: 42px;
            max-width: 593px;
            justify-content: space-between;
            flex-wrap: wrap;

            img {
              display: block;
              max-height: 42px;
              max-width: 180px;
            }

            div {
              flex-grow: 1;
              flex-basis: 180px;
              flex-shrink: 1;

              ${mediaQuery.greaterThen(600)} {
                flex-shrink: 0;
              }
            }

            p {
              flex-basis: 1;
              flex-shrink: 1;
              margin: 0;
              font-size: 16px;

              ${mediaQuery.greaterThen(600)} {
                flex-shrink: 0;
                border-left: 2px solid ${colors.offWhite};
                padding-left: 1ch;
              }
            }
          `}
        >
          <div>
            <img src={LogoSarros} alt="" />
          </div>

          <p>
            Soutien aux régions pour le recrutement
            <br />
            d’omnipraticiens et de spécialistes
          </p>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;

          grid-row: 4 / span 1;
          grid-column: 1 / span 1;

          p {
            margin: 0;
          }

          ${mediaQuery.greaterThen(1024)} {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            grid-row: 2 / span 1;
            grid-column: 2 / span 1;
          }
        `}
      >
        <p>
          © {new Date().getFullYear()} SARROS — Tous droits réservés. <br />
          <Link to="politique-de-confidentialite">
            Politique de confidentialité
          </Link>
        </p>

        <div
          css={css`
            display: flex;
            align-items: center;
            margin-top: 2em;

            a:not(:first-child) {
              margin-left: 1em;
            }

            ${mediaQuery.greaterThen(1024)} {
              margin-top: 0;

              a:not(:first-child) {
                margin-left: 0.7em;
              }
            }
          `}
        >
          <a href="https://www.facebook.com/equipesarros/">
            <LogoFacebook css={{ fill: colors.offWhite, width: "30px" }} />
          </a>

          <a href="https://www.instagram.com/sarros_qc/">
            <LogoInstagram css={{ fill: colors.offWhite, width: "30px" }} />
          </a>

          <a href="https://www.youtube.com/channel/UCCItycdtzuXcnKi3EVBaU8g/">
            <LogoYoutube css={{ fill: colors.offWhite, width: "43px" }} />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default SiteFooter
